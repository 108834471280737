import React from 'react'
import { graphql, Link } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Pouring Concrete Using Buckets' },
    { img: data.i2, caption: 'EPICORE MSR Concrete Pouring' },
  ];

  return (
    <Layout>
      <SEO title="System Overview: EPICORE MSR Concrete Pouring" keywords={[]}/>
      <div className="container system-overview-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Overview</h2>
        <h1 className='title title--xxxl color-primary'>EPICORE MSR Concrete Pouring</h1>

        <div className='grid grid--gutterless'>
          <div className='grid__column grid__column--12 grid__column--4--md'>
            <p>Once the load-bearing metal stud wall panels, Epicore MSR deck, shoring and
            structural steel beams and columns are installed, the concrete is poured on the
            Epicore MSR Composite Floor System.  The concrete slab thickness is a function
            of span and/or fire rating requirements, but is usually between 4.5” and 6”
            thick (total slab thickness from bottom of deck to top of slab). A majority
            of the Epicore MSR slabs use 4,000 psi normal weight concrete, although 3,000
            psi or lightweight concrete may be used if desired (consult Infinity).</p>
            <p className='type--lg'>
              <Link className="link-next" to="/system-overview/slab-beams/">
                Next Page: Slab Beams
              </Link>
            </p>
            <p>
              <Link className="link-prev" to="/system-overview/shoring-reshoring/">
                Prev Page: Shoring / Reshoring
              </Link>
            </p>
          </div>

          <div className='grid__column grid__column--12 grid__column--8--md'>
            <div className='carousel-container carousel-container--padding-x'>
              <Carousel
                content={images}
                carWidth={474}
                carHeight={316}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-overview/pouring-concrete/gallery/01-Pouri010_(41379).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i2: file(relativePath: { eq: "system-overview/pouring-concrete/gallery/02-015_14A_(not_in_program).jpg" }) {
            ...SystemOverviewCarouselImageFragment },
    }
`
